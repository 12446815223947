<template>
  <div>
    <div class="d-flex flex-wrap align-center">
      <v-switch
        v-model="isOn"
        label="SIMPLE"
        hide-details
        inset
        class="pa-0 ma-0 pb-4"
        @click="$emit('is-on', isOn)"
      />

      <transition name="fadeLeft">
        <v-row
          v-if="isOn"
          style="animation-duration: 0.3s;"
          class="ms-2 d-inline-flex"
        >
          <v-col style="width: auto; max-width: min-content;">
            <v-select
              v-model="nearestTo"
              :items="nearestToValues"
              item-text="value"
              suffix="分単位"
              class="pa-0 ma-0"
              :menu-props="{ auto: true, offsetY: true }"
              style="width: auto; max-width: min-content;"
            />
          </v-col>
          <v-col style="width: auto; max-width: min-content;">
            <v-select
              v-model="method"
              :items="methodValues"
              item-text="text"
              class="pa-0 ma-0"
              :menu-props="{ auto: true, offsetY: true }"
              style="width: auto; max-width: min-content; min-width: 92px;"
            />
          </v-col>
        </v-row>
      </transition>
    </div>

    <v-snackbar
      :value="showMsg"
      top
      centered
      timeout="2000"
    >
      SIMPLEモードは端末依存の設定です。この設定はこの端末のみに保存されます。
    </v-snackbar>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { mdiDotsVertical } from '@mdi/js'

export default {
  setup(_, { emit }) {
    const key = 'simpleClockIn'
    const stored = JSON.parse(localStorage.getItem(key) || '{}')

    // 初期値は localStorage から取得。nearestTo の有無で isOn を判定
    const isOn = ref(!!stored.nearestTo || !!stored.method)
    const nearestTo = ref(stored.nearestTo || null)
    const method = ref(stored.method || null)
    const showMsg = ref(false)

    const nearestToValues = [
      { value: 1 },
      { value: 5 },
      { value: 10 },
      { value: 15 },
      { value: 20 },
      { value: 30 },
    ]
    const methodValues = [
      { text: '四捨五入', value: 'round' },
      { text: '切り上げ', value: 'ceil' },
      { text: '切り捨て', value: 'floor' },
    ]

    // localStorage 更新用ユーティリティ
    const updateStorage = () => {
      localStorage.setItem(
        key,
        JSON.stringify({
          nearestTo: nearestTo.value,
          method: method.value,
        }),
      )
    }

    // isOn の変化に合わせて nearestTo と method の初期値をセット
    watch(isOn, (newVal, oldVal) => {
      if (newVal) {
        if (!nearestTo.value) nearestTo.value = 1
        if (!method.value) method.value = 'round'
      }
      if (oldVal !== undefined && !oldVal && newVal) {
        showMsg.value = true
      }

      updateStorage()
      emit('is-on', newVal)
    }, { immediate: true })

    watch(nearestTo, newVal => {
      // nearestTo の値が有効かチェック。無効な場合は isOn をオフにする
      if (!nearestToValues.some(item => item.value === newVal)) {
        isOn.value = false
        nearestTo.value = null
      }
      updateStorage()
    })

    watch(method, newVal => {
      // method の値が有効かチェック。無効な場合は isOn をオフにする
      if (!methodValues.some(item => item.value === newVal)) {
        isOn.value = false
        method.value = null
      }
      updateStorage()
    })

    // 初期状態を emit
    emit('is-on', isOn.value)

    return {
      isOn,
      nearestTo,
      method,
      showMsg,
      nearestToValues,
      methodValues,
      icons: {
        mdiDotsVertical,
      },
    }
  },
}
</script>
