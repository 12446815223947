import { map } from 'lodash'
import { formatISO } from 'date-fns'

export default () => {
  const buildBreakTimecardForm = ({
    id = null,
    breakTimeStartAt = '',
    breakTimeEndAt = '',
    _destroy = false,
  }) => {
    return {
      id,
      breakTimeStartAt,
      breakTimeEndAt,
      _destroy,
    }
  }

  const buildTimecardForm = ({
    id = null,
    startAt = '',
    leaveAt = '',
    userId = null,
    breakTimecardsAttributes = [],
  }) => {
    const form = {
      id,
      startAt,
      leaveAt,
      userId,
      breakTimecardsAttributes,
    }

    if (form.breakTimecardsAttributes.length < 1) {
      form.breakTimecardsAttributes.push(buildBreakTimecardForm({}))
    }

    return form
  }

  const buildTimecardEditForm = timecardData => {
    const {
      startAt,
      leaveAt,
      userId,
      breakTimecards,
    } = timecardData.attributes

    const breakTimecardsAttributes = map(breakTimecards.data, breakTimecard => {
      const { breakTimeStartAt, breakTimeEndAt } = breakTimecard.attributes

      return buildBreakTimecardForm({
        id: breakTimecard.id,
        breakTimeStartAt,
        breakTimeEndAt,
      })
    })

    const form = buildTimecardForm({
      id: timecardData.id,
      startAt,
      leaveAt,
      userId,
      breakTimecardsAttributes,
    })

    return form
  }

  // ユーザーの指定に応じた丸め処理を行う関数
  const roundTime = (date, { nearestTo = 1, method = 'round' } = {}) => {
    const ms = date.getTime()
    // 現在時刻を分単位の数値に変換
    const minutes = ms / 60000
    let newMinutes
    // method に応じて丸め処理を変更
    if (method === 'ceil') {
      newMinutes = Math.ceil(minutes / nearestTo) * nearestTo
    } else if (method === 'floor') {
      newMinutes = Math.floor(minutes / nearestTo) * nearestTo
    } else {
      newMinutes = Math.round(minutes / nearestTo) * nearestTo
    }

    return new Date(newMinutes * 60000) // 丸めた分を元の日時に戻す
  }

  const simpleCurrentTime = () => {
    const { nearestTo, method } = JSON.parse(localStorage.getItem('simpleClockIn') || '{}')
    if (!nearestTo) return formatISO(new Date())

    return formatISO(roundTime(new Date(), { nearestTo, method }))
  }

  return {
    buildTimecardForm,
    buildTimecardEditForm,
    simpleCurrentTime,
  }
}
